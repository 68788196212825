import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const areaOfInterestOptions = [
  {
    id: 1,
    name: "Air Pollution Control Equipments",
    sub: [
      "Low NOx Burners",
      "Electrostatic Precipitators (ESP)",
      "Bag Filter / Bag House",
      "Flue Gas Conditioning System for Waste to Energy",
      "Ammonia Flue Gas Conditioning",
      "Mist Separators – Candle Filters",
    ],
  },
  {
    id: 2,
    name: "Material Handling",
    sub: [
      "Pneumatic Conveying System / Ash Handling System",
      "Rotary Airlock Valve",
      "Slide Gate",
      "Double Cone Valve",
      "Double Flap Valve",
      "Clam Shell Gate / Sector Gate",
      "Dome Valve",
      "Drag Chain Conveyor",
      "Belt Conveyor",
      "Screw Conveyor",
    ],
  },
  {
    id: 3,
    name: "Process Equipments",
    sub: [
      "Silencers",
      "Defence Equipments",
      "Storage Tank",
      "Agitators",
      "Decomposers",
      "Pressure Vessels",
      "Jacketed Vessels",
    ],
  },
  {
    id: 4,
    name: "Inspection & Testing",
    sub: [
      "Computational Fluid Dynamics (CFD)",
      "GD Testing(ESP)",
      "Emission Testing",
      "Supervisory Services",
    ],
  },
  {
    id: 5,
    name: "Projects Management",
    sub: [
      "Erection & Commissioning Services",
      "Retrofits",
      "Upgrades",
      "Residual Life Assessment",
      "Shut Down Maintenance",
      "Annual Maintenance",
    ],
  },
  {
    id: 6,
    name: "Plant Maintenance & Spares",
    sub: ["Spares for ESP", "Spares for Bag House", "Spares for Cement Plant"],
  },
];

const GetInTouch = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState("");
  const [isSubFieldVisible, setIsSubFieldVisible] = useState(false);

  const [countryCode, setCountryCode] = useState("in");
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        setCountryCode("in")
        console.error("Error fetching IP information:", error);
      });
  }, []);

  const handleAreaChange = (e) => {
    const selectedId = e.target.value;
    const selectedArea = areaOfInterestOptions.find(
      (area) => area.id === parseInt(selectedId, 10)
    );

    setSelectedAreaId(selectedId);
    setSelectedAreaName(selectedArea ? selectedArea.name : "");
    setSubOptions(selectedArea ? selectedArea.sub : []);
    setSelectedSubOption("");

    // Show the sub-field if an area is selected
    setIsSubFieldVisible(selectedId !== "");
  };

  const handleSubOptionChange = (e) => {
    setSelectedSubOption(e.target.value);
  };

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      designation: "",
      organisation: "",
      message: "",
      areaOfInterest: "",
      subField: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .test(
          "no-first-space",
          "First letter should not be a space",
          (value) => value && !/^ /.test(value)
        )
        .required("Name is required")
        .test(
          "only-one-space-between",
          "Only one space allowed",
          (value) => value && !/ {2,}/.test(value)
        )
        .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
        .min(3, "Name must be at least 3 characters long")
        .max(50, "Name must be not more than 50 characters long"),
      designation: Yup.string()
        .required("Designation is required")
        .test(
          "only-one-space-between",
          "Only one space allowed between words",
          (value) => value && !/ {2,}/.test(value)
        )
        .min(3, "Designation must be at least 3 characters long")
        .max(50, "Designation must be not more than 50 characters long"),
      organisation: Yup.string()
        .required("Organisation is required")
        .min(3, "Organisation must be at least 3 characters long")
        .max(50, "Organisation must be not more than 50 characters long"),
        email: Yup.string()
        .email("Please enter a valid email address")
        .test(
          "no-com.co",
          "Please enter a valid email address",
          (value) => !/\.com\.co$/.test(value)
        )
        .test(
          "no-single-letter-after-com",
          "Please enter a valid email address",
          (value) => !/\.com\.[a-zA-Z]$/.test(value)
        )
        .test(
          "no-spaces",
          "Please enter a valid email address",
          (value) => !/\s/.test(value)
        )
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Please enter a valid email address"
        )
        .test(
          "only-one-dot-com",
          "Please enter a valid email address",
          (value) => (value.match(/\.com/g) || []).length <= 1
        )
        .test(
          "no-repeated-domain",
          "Please enter a valid email address",
          (value) => {
            const domainExtensions = [".com", ".net", ".in", ".co"];
            const matches = value.match(/\.\w+/g);
            if (matches) {
              const uniqueExtensions = new Set(
                matches.map((match) => match.toLowerCase())
              );
              return uniqueExtensions.size === matches.length;
            }
            return true;
          }
        )
        .test(
          "laxEmail_new",
          "Please enter a valid email address.",
          function (value) {
            if (value) {
              if (
                value.indexOf("@") > 0 &&
                value.indexOf("@") === value.lastIndexOf("@")
              ) {
                const [localPart, domain] = value.split("@");
                const firstChar = localPart[0];
                const lastChar = localPart.charAt(localPart.length - 1);
                const afterAt = domain[0];
    
                if (
                  firstChar === "." ||
                  lastChar === "." ||
                  /^[A-Za-z]$/.test(afterAt) === false
                ) {
                  return false;
                }
    
                const domainParts = domain.split(".");
                if (domainParts.length < 2) {
                  return false;
                }
    
                const topLevelDomain = domainParts.pop();
                if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
                  return false;
                }
    
                return domainParts.every((part) => part.length > 1);
              } else {
                return false;
              }
            }
            return true;
          }
        )
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required")
      .min(9, "Please enter a valid phone number"),
      message: Yup.string()
        
        .min(3, "Your Message must be at least 3 characters")
        .max(500, "Message must be not more than 500 characters long"),
      areaOfInterest: Yup.string().required(
        "Please select an area of interest"
      ),
      subField: Yup.string().required("Please select a sub-option"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          "backend/Contact_form.php",
          {
            ...values,
            areaOfInterest: selectedAreaName,
            subField: selectedSubOption,
            mode: "addCenterModuleData_new",
          }
        );
        const responseData = response.data;
        if (responseData === 1) {
          formik.resetForm();
          setSelectedAreaName("");
          setSubOptions([]);
          setSelectedSubOption("");
          navigate("/thank-you");
          window.location.reload();
        } else {
          console.error("Error submitting form data");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      setIsSubmitting(false);
    },
  });

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header p-3">
            <h6 className="section-sub_heading mb-0" id="staticBackdropLabel">
              Get in Touch!
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="reach-us_form">
              <form
                id="contactform"
                className="contactform"
                onSubmit={formik.handleSubmit}
              >
                <div className="row g-4 p-3">
                  <div className="col-12 mt-3">
                    <div className="form-group position-relative">
                      <label>
                        Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Full Name"
                        onChange={formik.handleChange}
                        onInput={handleNameInput}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="error-msg text-danger">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-group position-relative">
                      <label>
                        Designation <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="designation"
                        name="designation"
                        className="form-control"
                        placeholder="Enter Your Designation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.designation}
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <div className="error-msg text-danger">
                            {formik.errors.designation}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-group position-relative">
                      <label>
                        Organisation <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="organisation"
                        name="organisation"
                        className="form-control"
                        placeholder="Enter Your Organisation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organisation}
                      />
                      {formik.touched.organisation &&
                        formik.errors.organisation && (
                          <div className="error-msg text-danger">
                            {formik.errors.organisation}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-group position-relative">
                      <label>
                        Email <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        onKeyDown={handleKeyPress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="error-msg text-danger">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-group position-relative">
                      <label>
                        Phone <span className="text-red">*</span>
                      </label>

                      <PhoneInput
                        country={countryCode}
                        value={formik.values.phone}
                        onChange={(value) =>
                          formik.setFieldValue("phone", value)
                        }
                        onBlur={formik.handleBlur}
                        inputStyle={{ width: "100%" }}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className="error-msg text-danger">
                          {formik.errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group position-relative">
                      <label>
                        Area of Interest <span className="text-danger">*</span>
                      </label>

                      <select
                        name="areaOfInterest"
                        className="form-select border"
                        aria-label="Default select example"
                        value={selectedAreaId}
                        id="connectareaOfInterest"
                        onChange={(e) => {
                          // Call the area change handler
                          handleAreaChange(e);

                          // Reset the subfield selection
                          formik.setFieldValue("subField", ""); // Reset the selected subField

                          // Mark subField as touched to show the error message
                          formik.setFieldTouched("subField", true); // Mark it as touched to trigger error display

                          // Call Formik's handleChange
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select</option>
                        {areaOfInterestOptions.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.areaOfInterest &&
                        formik.errors.areaOfInterest && (
                          <div className="error-msg text-danger">
                            {formik.errors.areaOfInterest}
                          </div>
                        )}
                    </div>
                  </div>

                  {isSubFieldVisible && (
                    <div className="col-lg-12 mt-0">
                      <div className="form-group position-relative">
                        <select
                          name="subField"
                          className="form-select mt-2 mb-1 border"
                          aria-label="Default select example"
                          value={selectedSubOption}
                          onChange={(e) => {
                            handleSubOptionChange(e);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          disabled={!subOptions.length}
                          id="connectussubField"
                        >
                          <option value="">Select</option>
                          {subOptions.map((subOption, index) => (
                            <option key={index} value={subOption}>
                              {subOption}
                            </option>
                          ))}
                        </select>
                        {formik.touched.subField && formik.errors.subField && (
                          <div className="error-msg text-danger">
                            {formik.errors.subField}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  
                  <div className="col-12 mt-3">
                    <div className="form-group position-relative">
                      <label>
                        Message <small className="">(Optional)</small>
                      </label>
                      <textarea
                        id="message"
                        rows="3"
                        name="message"
                        className="form-control"
                        style={{ cursor: "auto" }}
                        placeholder="Write Message..."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      />
                      {formik.touched.message && formik.errors.message && (
                        <div className="error-msg text-danger">
                          {formik.errors.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer col-12 text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "18px",
                        textTransform: "none",
                        backgroundColor: "#052d83",
                        border: "1px solid #052d83",
                        padding: "8px 24px",
                        borderRadius: "8px",
                        whiteSpace: "normal",
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
